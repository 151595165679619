import { Handle, Position, type NodeProps } from '@xyflow/react';
import { type SQLExplainNode } from './types';
import { NodeContainer, Label, DetailText, AccessMethodValue, IndexTag } from './SQLExplainNodeStyles';

export const SQLExplainNodeComponent = ({ data }: NodeProps<SQLExplainNode>) => {
    const {
        type = 'N/A',
        accessMethod,
        indexName,
        estimatedRows = 'N/A',
        queryCost,
        label = 'N/A',
        filterCondition,
        filteredPercentage,
        rowsAfterFilter,
    } = data;

    const accessMethodDisplay = accessMethod === 'ALL' ? 'Table Scan' : 'Index';
    const isTableScan = accessMethodDisplay === 'Table Scan';
    const formattedQueryCost =  queryCost?.toLocaleString() || 'No cost info in EXPLAIN';

    return (
        <NodeContainer nodeType={type}>
            <Label nodeType={type}>{label}</Label>
            <div style={{ paddingTop: '2.5rem' }}>
                {type === 'table' ? (
                    <>
                        <DetailText>
                            Access Method: <AccessMethodValue isTableScan={isTableScan}><strong>{accessMethodDisplay}</strong></AccessMethodValue>
                        </DetailText>
                        {indexName && (
                            <DetailText>Index Used: <IndexTag>{indexName}</IndexTag></DetailText>
                        )}
                        <DetailText>Estimated Rows: <strong>{estimatedRows}</strong></DetailText>
                    </>
                ) : (
                    <>
                        <DetailText>Estimated Rows: <strong>{estimatedRows}</strong></DetailText>
                        {type === 'result' && <DetailText>Query Cost: <strong>{formattedQueryCost}</strong></DetailText>}
                    </>
                )}
            </div>
            <Handle type="target" position={Position.Bottom}
                    style={{ background: 'transparent', border: 'none' }}

            />
            <Handle type="source" position={Position.Top}
                    style={{ background: 'transparent', border: 'none' }}
            />
        </NodeContainer>
    );
};
