import { useAuth, useLoginActions } from '@frontegg/react';
import React, { useContext, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import * as Sentry from '@sentry/react';
import { useSetRecoilState } from 'recoil';

import {
  Error,
  RecentActivity,
  Home,
  General,
  ErrorPage,
  PerformanceDetails,
  Reports,
  Login,
  QueryAnalyzer,
  Projects,
  ProjectItem,
  SchemaAnalysis,
  TableAnalysis,
  SchemaMigration,
  MigrationAnalysis,
  ObservabilityDashboard,
  HostDashboard,
  TestPage,
  Enrichment,
  Logs,
  Monitoring,
  ProductionIssues,
  Rules,
  Alerts,
  Integrations,
  ComposedRules,
  NewRule,
  WelcomePage
} from './routes';
import { TableRowSize } from './routes/Reports/reports/TableRowSize';
import { RestClientContext } from './auth/RestClientAuthProvider';
import { UserSettings, userSettingsAtom } from './atoms/user.settings.atom';
import { IndexUsage } from './routes/Reports/reports/IndexUsage';
import ErrorFallback from './core/ErrorFallback';
import { debugModeState } from './atoms/debug-mode.atom';
import { analyticsIdentity } from './utils/segment-analytics';
import SplashScreen from './components/SplashScreen/SplashScreen';
import { getCookie, getHighestRole } from './utils/utils';
import { QueryStatistics } from './routes/Reports/reports/QueryStatistics';
import { DEMO_USER_EMAIL, isDemo } from './common/consts';
import { DbConfigReport } from './routes/Reports/reports/DbConfigReport';
import { DbExtensionsReport } from './routes/Reports/reports/DbExtensionsReport';
import SettingsRoute from './routes/Settings/SettingsRoute';
import TestCICard from './routes/TestPage/TestCICard';
import ProductionTab from './routes/ObservabilityDashboard/ProductionTab';
import ComparePage from "./routes/ComparePage/ComparePage";
import { IndexAdvisor } from './routes/IndexAdvisor/IndexAdvisor';
import {ReactFlowProvider} from "reactflow";

const ProtectedRoute = ({ component }: any) => {
  const Component = component;
  return (
    <Sentry.ErrorBoundary fallback={({ error, resetError }) => <ErrorFallback error={error} resetError={resetError} />}>
      <React.Suspense>
        <Component />
      </React.Suspense>
    </Sentry.ErrorBoundary>
  );
};

const App = () => {
  const restClient = useContext(RestClientContext);
  const setUserSettings = useSetRecoilState(userSettingsAtom);
  const { isAuthenticated, isLoading: authIsLoading, user } = useAuth();
  const setDebugMode = useSetRecoilState(debugModeState);

  useEffect(() => {
    setDebugMode(localStorage.getItem('debug_mode') === 'true');
  }, []);
  const ga = new String(getCookie('_ga')).replace(/(G[a-zA-Z])\w.\w./g, '');

  const { isLoading } = useQuery(
    'init',
    () => restClient?.get('init', { searchParams: { gaId: ga } }).json<{ settings: UserSettings }>(),
    {
      refetchOnWindowFocus: false,
      enabled: isAuthenticated,
      onSuccess: (data: any) => {
        const userRole = getHighestRole(user?.roles || []);
        setUserSettings({
          defaultApiKey: data?.settings?.defaultApiKey,
          userRole,
          id: data?.id,
          tenantId: user?.tenantId,
          hostsApiKey: data?.hostsApiKey,
        }); /* Identify a visitor */
        analyticsIdentity(data?.providerId, {
          email: user?.email,
          providedId: data?.providedId
        });
      }
    }
  );

  useEffect(() => {
    if (isDemo && isAuthenticated && user?.email !== DEMO_USER_EMAIL) {
      silentLogoutInDemo();
    }
  }, []);

  const loginActions = useLoginActions();
  const navigate = useNavigate();
  const silentLogoutInDemo = () => {
    loginActions.logout(() => {
      navigate('/login');
    });
  };

  if (isLoading || authIsLoading) {
    return <SplashScreen />;
  }
  return (
    <>
      <ReactFlowProvider>
      <Routes>
        <Route path="/" element={<Home />}>
          <Route path="settings" element={<SettingsRoute />}>
            <Route index element={<ProtectedRoute component={General} />} />
            <Route path="activity" element={<ProtectedRoute component={Logs} />} />
            <Route path="enrichment" element={<ProtectedRoute component={Enrichment} />} />
          </Route>
          <Route
            path="error"
            element={
              <React.Suspense fallback={<>...</>}>
                <ErrorPage />
              </React.Suspense>
            }
          />
          <Route path="query-analysis" element={<ProtectedRoute path={'/query-analysis'} component={QueryAnalyzer} />} />
          <Route path="index-advisor" element={<ProtectedRoute path={'/index-advisor'} component={IndexAdvisor} />} />
          <Route path="compare" element={<ProtectedRoute path={'/compare'} component={ComparePage} />} />

          <Route path="welcome" element={<ProtectedRoute component={WelcomePage} />} />
          <Route path="projects">
            <Route index element={<ProtectedRoute component={Projects} />} />
            <Route path=":projectApiKey">
                <Route element={<ProtectedRoute component={ProjectItem} />}>
                <Route path="" element={<ProtectedRoute component={RecentActivity} />} />
                <Route path="activities" element={<ProtectedRoute component={RecentActivity} />} />
                <Route path="test" element={<ProtectedRoute component={TestCICard} />} />
                  <Route path="dashboard" element={<ProtectedRoute component={ProductionTab} />} />
              </Route>
              <Route path="dashboard/:hostId/:databaseId" element={<ProtectedRoute component={ObservabilityDashboard} />} />
              <Route path="dashboard/:hostId" element={<ProtectedRoute component={HostDashboard} />} />
              <Route path="reports" element={<ProtectedRoute component={Reports} />}>
                <Route path=":hostId/:databaseId/table-row-size-trend" element={<ProtectedRoute component={TableRowSize} />} />
                <Route path=":hostId/:databaseId/index-usage" element={<ProtectedRoute component={IndexUsage} />} />
                <Route path=":hostId/:databaseId/query-statistics" element={<ProtectedRoute component={QueryStatistics} />} />
                <Route path=":hostId/:databaseId/db-config" element={<ProtectedRoute component={DbConfigReport} />} />
                <Route path=":hostId/:databaseId/db-extensions" element={<ProtectedRoute component={DbExtensionsReport} />} />
              </Route>
              <Route path="dashboard">
                <Route path=":traceId" element={<ProtectedRoute component={ProductionIssues} />} />
              </Route>

              <Route path="test/:testName">
                <Route index element={<ProtectedRoute component={TestPage} />} />
                <Route path="migration/:migrationID">
                  <Route index element={<ProtectedRoute component={SchemaMigration} />} />
                  <Route path="analysis" element={<ProtectedRoute component={MigrationAnalysis} />} />
                </Route>
                <Route path=":performanceId" element={<ProtectedRoute component={PerformanceDetails} />} />
              </Route>
              <Route path="activities">
                <Route path="source/:source" element={<ProtectedRoute component={TestPage} />} />
                <Route path="source/:source/:performanceId" element={<ProtectedRoute component={PerformanceDetails} />} />
                <Route path=":performanceId" element={<ProtectedRoute component={PerformanceDetails} />} />
              </Route>
              <Route path="schemaAnalysis/:hostId/:databaseId">
                <Route index element={<ProtectedRoute component={SchemaAnalysis} />} />
                <Route path=":tableID" element={<ProtectedRoute component={TableAnalysis} />} />
              </Route>
            </Route>
          </Route>
          <Route path=":monitoring">
            <Route index element={<ProtectedRoute path={'/'} component={Monitoring} />} />
              <Route path="dashboard/:hostId/:databaseId" element={<ProtectedRoute component={ObservabilityDashboard} />} />
              <Route path="dashboard/:hostId" element={<ProtectedRoute component={HostDashboard} />} />
              <Route path="dashboard/" element={<ProtectedRoute component={HostDashboard} />} />
              <Route path="reports" element={<ProtectedRoute component={Reports} />}>
                <Route path=":hostId/db-config" element={<ProtectedRoute component={DbConfigReport} />} />
                <Route path=":hostId/:databaseId/table-row-size-trend" element={<ProtectedRoute component={TableRowSize} />} />
                <Route path=":hostId/:databaseId/index-usage" element={<ProtectedRoute component={IndexUsage} />} />
                <Route path=":hostId/:databaseId/query-statistics" element={<ProtectedRoute component={QueryStatistics} />} />
                <Route path=":hostId/:databaseId/db-extensions" element={<ProtectedRoute component={DbExtensionsReport} />} />
              </Route>
              <Route path="schemaAnalysis/:hostId/:databaseId">
                <Route index element={<ProtectedRoute component={SchemaAnalysis} />} />
                <Route path=":tableID" element={<ProtectedRoute component={TableAnalysis} />} />
              </Route>
          </Route>
          <Route path="integrations">
            <Route index element={<ProtectedRoute component={Integrations} />} />
          </Route>
          <Route path="alerts">
            <Route index element={<ProtectedRoute component={Alerts} />} />
          </Route>
          <Route path="rules">
            <Route index element={<ProtectedRoute component={Rules} />} />
            <Route path="create" element={<ProtectedRoute component={NewRule} />} />
            <Route path=":ruleId" element={<ProtectedRoute component={ComposedRules} />} />
          </Route>
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/login/:loginType" element={<Login />} />
        <Route path="/signup" element={<Login signUp />} />
        <Route path="*" element={<Error />} />
      </Routes>
      </ReactFlowProvider>
    </>
  );
};

export default App;
