import {Handle, type NodeProps, Position} from '@xyflow/react';
import { type SQLExplainNode } from './types';
import {SubQueryContainer, SubQueryLabel} from './SQLSubQueryStyles'
import React from "react";

export const SQLSubQueryComponent = ({ data }: NodeProps<SQLExplainNode>) => {
    const { label = 'N/A', width = 300, height = 200 } = data;

    return (
        <SubQueryContainer width={Number(width)} height={Number(height)}>
            <SubQueryLabel>{label}</SubQueryLabel>
            <Handle type="target" position={Position.Bottom}
                    style={{ background: 'transparent', border: 'none' }}
            />
            <Handle type="source" position={Position.Top}
                    style={{ background: 'transparent', border: 'none' }}
            />
        </SubQueryContainer>
    );
};
