import { MarkerType } from '@xyflow/react';
import { SQLExplainNode, ParseContext, SQLExplainEdge } from './dbParserTypes';

export const createEdge = (source: string, target: string, context: ParseContext, reverse: boolean = false): SQLExplainEdge => {
  const edgeId = `edge_${context.nodeIdCounter++}`;
  const edge: SQLExplainEdge = {
    id: edgeId,
    source: reverse ? target : source, 
    target: reverse ? source : target,
    animated: true,
    markerEnd: { type: MarkerType.ArrowClosed }, 
    style: { strokeWidth: 5 },
  };

  context.edges.push(edge);
  return edge;
};

export const  createTableNode = (
    tableObj: any,
    context: ParseContext,
    parentNodeId: string | null
): SQLExplainNode => {
  const nodeId = `node_${context.nodeIdCounter++}`;
  const node: SQLExplainNode = {
    id: nodeId,
    type: 'sql-explain-node',
    data: {
      label: tableObj.table_name?.length <= 15 ? `Table ${tableObj.table_name}` : `Table ${tableObj.table_name.substring(0,15)}...`,
      type: 'table',
      accessMethod: tableObj.access_type,
      indexName: tableObj.key || tableObj.index_name || null,
      estimatedRows: tableObj.rows_produced_per_join
          ? Number(tableObj.rows_produced_per_join).toLocaleString()
          : 'N/A',
      rowsAfterFilter: tableObj.rows_produced_per_join?.toLocaleString()
          ? Number(tableObj.rows_produced_per_join).toLocaleString()
          : 'N/A',
      filteredPercentage: tableObj.filtered ? `${tableObj.filtered}%` : null,
      filterCondition: tableObj.attached_condition || tableObj.filter_condition || null,
      tableObj
    },
    position: {
      x: 0,
      y: 0
    },
    parentId: parentNodeId || context.currentParentId || undefined,
    extent: 'parent',
  };

  context.nodes.push(node);
  context.currentXPosition =0;

  return node;
}



export const createGroupByNode = (context: ParseContext, parentId?: string): SQLExplainNode => {
  const nodeId = `node_${context.nodeIdCounter++}`;
  const node: SQLExplainNode = {
    id: nodeId,
    type: 'sql-explain-node',
    data: {
      label: 'Group By',
      type: 'group_by',
    },
    position: {
      x: 0,
      y: 0
    },
    ...(context.hasUnion || context.hasSubQuery ? { parentId, extent: 'parent' } : {})
  };

  context.nodes.push(node);
  context.currentYPosition =0;

  return node;
};

export const createJoinNode = (
    estimatedRows: number | null,
    filter: string,
    context: ParseContext,
    parentNodeId: string | null
): SQLExplainNode => {
  const nodeId = `node_${context.nodeIdCounter++}`;
  const node: SQLExplainNode = {
    id: nodeId,
    type: 'sql-explain-node',
    data: {
      label: 'Join',
      type: 'join',
      estimatedRows: estimatedRows ? estimatedRows.toString() : 'N/A',
      filter,
    },
    position: {
     x: 0,
      y: 0,
    },
    parentId: parentNodeId || context.currentParentId || undefined,
    extent: 'parent',

  };

  context.currentXPosition =0
  context.currentYPosition =0


  return node;
}

export const createSortingNode = (context: ParseContext, parentId?: string): SQLExplainNode => {
  const nodeId = `node_${context.nodeIdCounter++}`;
  const node: SQLExplainNode = {
    id: nodeId,
    type: 'sql-explain-node',
    data: {
      label: 'Sorting Rows',
      type: 'sorting_rows',
      estimatedRows: context.latestRowsAfterFilter
          ? context.latestRowsAfterFilter?.toLocaleString()
          : 'N/A',
    },
    position: {
      x: 0,
      y: 0
    },
    ...(context.hasUnion || context.hasSubQuery ? { parentId, extent: 'parent' } : {})
  };

  context.nodes.push(node);
  context.currentYPosition =0;


  return node;
};

export const createResultNode = (queryBlock: any, context: ParseContext, estimatedRows: number | null = null, parentId?: string): SQLExplainNode => {
  const nodeId = `node_${context.nodeIdCounter++}`;
  const node: SQLExplainNode = {
    id: nodeId,
    type: 'sql-explain-node',
    data: {
      label: 'Query Result',
      type: 'result',
      queryCost: queryBlock.cost_info ? Number(queryBlock.cost_info.query_cost)  : null,
      estimatedRows: estimatedRows?.toLocaleString() || 'N/A',
    },
    position: {
      x: 0,
      y: 0,
    },
    ...(context.hasUnion || context.hasSubQuery ? { parentId, extent: 'parent' } : {})
  };

  context.nodes.push(node);
  context.currentYPosition =0;

  return node;
};

export const createSelectListNode = (context: ParseContext, parentId?: string): SQLExplainNode => {
  const nodeId = `node_${context.nodeIdCounter++}`;
  const node: SQLExplainNode = {
    id: nodeId,
    type: 'sql-explain-node',
    data: {
      label: 'Select List',
      type: 'select_list',
    },
    position: {
      x: 0,
      y: 0,
    },
    ...(context.hasUnion || context.hasSubQuery ? { parentId, extent: 'parent' } : {})
  };

  context.nodes.push(node);
  context.currentYPosition =0;

  return node;
};

export const createUnionNode = (
    context: ParseContext,
    parentId?: string
): SQLExplainNode => {
  const nodeId = `node_${context.nodeIdCounter++}`;
  const node: SQLExplainNode = {
    id: nodeId,
    type: 'sql-explain-node',
    data: {
      label: 'Union',
      type: 'union',
    },
    position: {
      x: 0,
      y:0
    },
    ...(context.hasUnion || context.hasSubQuery ? { parentId, extent: 'parent' } : {})
  };

  context.nodes.push(node);
  context.currentYPosition -= (context.verticalSpacing + 200);

  return node;
};

export const createDistinctNode = (
    context: ParseContext,
    parentId?: string
): SQLExplainNode => {
  const nodeId = `node_${context.nodeIdCounter++}`;
  const node: SQLExplainNode = {
    id: nodeId,
    type: 'sql-explain-node',
    data: {
      label: 'Distinct',
      type: 'duplicates_removal',
      estimatedRows: context.latestRowsAfterFilter
          ? context.latestRowsAfterFilter.toLocaleString()
          : 'N/A',
    },
    position: {
      x: 0,
      y: 0,
    },
    ...(context.hasUnion || context.hasSubQuery ? { parentId, extent: 'parent' } : {}),
  };

  context.nodes.push(node);
  context.currentYPosition =0;

  return node;
};
