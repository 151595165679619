import dagre from 'dagre';
import { SQLExplainNode, SQLExplainEdge } from './dbParserTypes';


const getNodeDimensions = (node: SQLExplainNode): { width: number; height: number } => {
    if (node.type === 'sql-explain-node') {
        return { width: 250, height: 100 };
    }
    if (node.type === 'sql-sub-query-node') {
        const baseWidth = (node.data?.width as number) || 400;
        const baseHeight = (node.data?.height as number) || 300;
        return { width: baseWidth, height: baseHeight };
    }
    return { width: 0, height: 0 };
};

const getAdjustedDimensionsForLayout = (node: SQLExplainNode): { width: number; height: number } => {
    const dims = getNodeDimensions(node);
    if (node.type === 'sql-sub-query-node') {
        const extraPadding = 0.3;
        return { width: dims.width, height: dims.height * extraPadding };
    }
    return dims;
};

export const applyGlobalLayout = (nodes: SQLExplainNode[], edges: SQLExplainEdge[]) => {
    const graph = new dagre.graphlib.Graph();
    graph.setDefaultEdgeLabel(() => ({}));
    graph.setGraph({ rankdir: 'BT', marginx: 50, marginy: 50,   ranksep: 80 });

    nodes.filter(n => !n.parentId).forEach((node) => {
        const { width, height } = getAdjustedDimensionsForLayout(node);
        graph.setNode(node.id, { width, height });
    });

    // Add only top-level edges.
    edges.forEach(edge => {
        const source = nodes.find(n => n.id === edge.source);
        const target = nodes.find(n => n.id === edge.target);
        if (source && target && !source.parentId && !target.parentId) {
            graph.setEdge(edge.source, edge.target);
        }
    });

    dagre.layout(graph);
    nodes.filter(n => !n.parentId).forEach((node) => {
        const pos = graph.node(node.id);
        if (pos) {
            const { width, height } = getNodeDimensions(node);
            node.position = { x: pos.x - width / 2, y: pos.y };
        }
    });
};

export const applyContainerLayout = (container: SQLExplainNode, children: SQLExplainNode[], edges: SQLExplainEdge[]) => {
    const graph = new dagre.graphlib.Graph();
    graph.setDefaultEdgeLabel(() => ({}));
    graph.setGraph({ rankdir: 'BT', marginx: 10, marginy: 10 , ranksep: 80, nodesep: 50 });

    children.forEach(child => {
        const { width, height } = getNodeDimensions(child);
        graph.setNode(child.id, { width, height });
    });

    // Add edges among children only.
    edges.forEach(edge => {
        if (children.find(c => c.id === edge.source) && children.find(c => c.id === edge.target)) {
            graph.setEdge(edge.source, edge.target);
        }
    });

    dagre.layout(graph);
    children.forEach(child => {
        const pos = graph.node(child.id);
        if (pos) {
            const { width, height } = getNodeDimensions(child);
            // Position relative to container's top-left.
            child.position = {
                x: container.position.x + pos.x - width / 2,
                y: container.position.y + pos.y - height / 2,
            };
        }
    });
};

export const updateContainerDimensions = (container: SQLExplainNode, children: SQLExplainNode[]) => {
    if (!children.length) return;

    // Base padding values.
    const BASE_HORIZONTAL_PADDING = 30;
    const BASE_VERTICAL_PADDING = 60;
    const EXTRA_LEFT_PADDING = 50; // extra gap if a child is a subquery

    // Compute the bounding box of the children.
    let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;
    children.forEach(child => {
        const { width, height } = getNodeDimensions(child);
        // If the child is a subquery node, adjust its effective x-position.
        const effectiveX = child.type === 'sql-sub-query-node' ? child.position.x - EXTRA_LEFT_PADDING : child.position.x;
        minX = Math.min(minX, effectiveX);
        minY = Math.min(minY, child.position.y);
        maxX = Math.max(maxX, child.position.x + width);
        maxY = Math.max(maxY, child.position.y + height);
    });

    // Use the (potentially lowered) minX to compute the container's top-left.
    container.position = {
        x: minX - BASE_HORIZONTAL_PADDING,
        y: minY - BASE_VERTICAL_PADDING
    };

    // Compute container dimensions based on the bounding box plus padding.
    container.data.width = Math.max((maxX - minX) + BASE_HORIZONTAL_PADDING * 2, 400);
    container.data.height = Math.max((maxY - minY) + BASE_VERTICAL_PADDING * 2, 300);

    // Convert each child's absolute position into one relative to the container's new top-left.
    children.forEach(child => {
        child.position = {
            x: child.position.x - container.position.x,
            y: child.position.y - container.position.y,
        };
    });
};