import { SQLEngineType, ParseContext, ParseResult } from './dbParserTypes';
import { MySQLParser } from './mySQLParser';

const parsers: Record<SQLEngineType, (data: any, context: ParseContext, SQLQuery: string) => ParseResult> = {
  MySQL: MySQLParser,
  PostgresSQL: () => { throw new Error('PostgresSQL parser not implemented yet'); },
  MSSQL: () => { throw new Error('MSSQL parser not implemented yet'); },
  Oracle: () => { throw new Error('Oracle parser not implemented yet'); },
};

export const parseExplainData = (data: any, engineType: SQLEngineType , SQLQuery: string): ParseResult => {
  const context: ParseContext = {
    currentLevel: 0,
    currentXPosition: -650,
    currentYPosition: -200,
    latestRowsAfterFilter: 0,
    nodeIdCounter: 1, 
    nodes: [], 
    edges: [], 
    horizontalSpacing: 200,
    verticalSpacing: 150,
  };
  
  const parser = parsers[engineType];
  if (!parser) throw new Error(`Unsupported SQL engine: ${engineType}`);

  return parser(data, context, SQLQuery);
};
