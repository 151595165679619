import {
  createDistinctNode,
  createUnionNode,
  createTableNode,
  createSortingNode,
  createResultNode,
  createEdge,
  createJoinNode,
  createGroupByNode,
  createSelectListNode
} from './mySQLNodeBuilder';
import { SQLOperationType, SQLExplainNode, ParseContext, ParseResult, SQLExplainEdge, NodeResult, NodeDiscoveryResult } from './dbParserTypes';
import { parseSQLToAST, extractAliasMap ,enrichNodes, replaceSQLKeywords } from './common'
import {
  applyGlobalLayout,
  applyContainerLayout,
  updateContainerDimensions,
} from './advancedLayout';

export const MySQLParser = (data: any, context: ParseContext, SQLQuery: string): ParseResult => {
  const nodes: SQLExplainNode[] = [];
  const edges: SQLExplainEdge[] = [];
  const createdTableNodes = new Map<string, Set<string>>();
  let ast;
  let aliasMap: any[] | undefined;

  createdTableNodes.set('default', new Set<string>());
  const discoveryResult = discoverNodes(data, context);
  recursiveNodeBuilder(data, context, nodes, edges, null, createdTableNodes, discoveryResult);

  try {
    const query = replaceSQLKeywords(SQLQuery);
    ast = parseSQLToAST(query);
    aliasMap = extractAliasMap(ast);
  } catch (error) {
    console.error('AST Parsing Error:', error);
  }

  enrichNodes(nodes, aliasMap);
  applyGlobalLayout(nodes, edges);
  const containerNodes = nodes.filter(n => n.type === 'sql-sub-query-node');
  containerNodes.reverse().forEach(container => {
    const children = nodes.filter(n => n.parentId === container.id);
    applyContainerLayout(container, children, edges);
    updateContainerDimensions(container, children);
  });

  applyGlobalLayout(nodes, edges);

  return { nodes, edges };
};

const discoverNodes = (
    obj: any,
    context: ParseContext,
    result: NodeDiscoveryResult = { hasNestedLoop: false, tableNames: new Set(), unionCount: 0, subqueryCount: 0 },
    level: number = 0
): NodeDiscoveryResult => {
  if (!context.discoveryTree) {
    context.discoveryTree = {};
  }

  for (const key in obj) {
    if (key === 'query_block') {
      const queryBlockKey = `query_block_${result.subqueryCount}`;
      context.discoveryTree[queryBlockKey] = { type: 'result', level: level };
      discoverNodes(obj[key], context, result, level);
    } else if (key === 'table') {
      const tableInfo = obj[key];
      const tableNode = { table_name: tableInfo.table_name, type: 'table', level: level };
      const tableKey = `table_${tableInfo.table_name}`;
      if (!context.discoveryTree[tableKey]) {
        context.discoveryTree[tableKey] = tableNode;
      }
      if (tableInfo.materialized_from_subquery) {
        result.subqueryCount += 1;
        const subQueryKey = `subquery_${result.subqueryCount}`;
        if (!context.discoveryTree[subQueryKey]) {
          context.discoveryTree[subQueryKey] = {
            type: 'subquery',
            level,
            children: 0,
            children_array: []
          };
        }
        const previousSubQueryKey = context.currentSubQueryKey;
        context.currentSubQueryKey = subQueryKey;
        discoverNodes(tableInfo.materialized_from_subquery.query_block, context, result, level);
        context.currentSubQueryKey = previousSubQueryKey;
      }
    } else if (key === 'grouping_operation') {
      const groupingNode = { type: 'group_by', level: level };
      if (context.currentSubQueryKey) {
        context.discoveryTree[context.currentSubQueryKey].children_array.push(groupingNode);
        context.discoveryTree[context.currentSubQueryKey].children += 1;
      }
      discoverNodes(obj[key], context, result, level);
    } else if (key === 'union_result' || key === 'union') {
      result.unionCount += 1;
      const unionKey = `union_${result.unionCount}`;
      context.discoveryTree[unionKey] = { type: 'union', level: level, children: 0, children_array: [] };
      const previousUnionKey = context.currentUnionKey;
      context.currentUnionKey = unionKey;
      if (obj[key].query_specifications) {
        for (const subQuery of obj[key].query_specifications) {
          discoverNodes(subQuery, context, result, level);
        }
      }
      context.currentUnionKey = previousUnionKey;
    }
    if (typeof obj[key] === 'object' && key !== 'materialized_from_subquery' && key !== 'query_block' && key !== 'union_result') {
      discoverNodes(obj[key], context, result, level);
    }
  }
  context.hasUnion = result.unionCount > 0;
  context.hasSubQuery = result.subqueryCount > 0;
  return result;
};

const recursiveNodeBuilder = (
    obj: any,
    context: ParseContext,
    nodes: SQLExplainNode[],
    edges: SQLExplainEdge[],
    parentNodeId: string | null,
    createdTableNodesMap: Map<string, Set<string>>,
    discoveryResult: NodeDiscoveryResult,
    estimatedRows: number | null = null,
    loopId: string = 'default'
): NodeResult => {
  let lastNodeId: string | null = parentNodeId;
  let accumulatedEstimatedRows: number | null = estimatedRows;

  for (const key in obj) {
    if (key === 'materialized_from_subquery') {
      const subQueryResult = handleSubQuery(
          obj[key],
          context,
          nodes,
          edges,
          parentNodeId,
          createdTableNodesMap,
          accumulatedEstimatedRows
      );
      if (subQueryResult.nodeId) {
        lastNodeId = subQueryResult.nodeId;
      }
      if (subQueryResult.estimatedRows != null) {
        accumulatedEstimatedRows = subQueryResult.estimatedRows;
      }
      continue;
    }

    if (key === 'attached_subqueries') {
      const subQueriesResult = handleMultipleSubQueries(
          obj[key],
          context,
          nodes,
          edges,
          parentNodeId,
          createdTableNodesMap,
          accumulatedEstimatedRows
      );
      if (subQueriesResult.nodeId) {
        lastNodeId = subQueriesResult.nodeId;
      }
      if (subQueriesResult.estimatedRows != null) {
        accumulatedEstimatedRows = subQueriesResult.estimatedRows;
      }
      continue;
    }

    if (keyToHandlerMap[key as SQLOperationType]) {
      const result = recursiveNodeBuilder(
          obj[key],
          context,
          nodes,
          edges,
          parentNodeId,
          createdTableNodesMap,
          discoveryResult,
          accumulatedEstimatedRows,
          loopId
      );
      if (result.nodeId) {
        lastNodeId = result.nodeId;
      }
      if (result.estimatedRows != null) {
        accumulatedEstimatedRows = result.estimatedRows;
      }
      const currentResult = keyToHandlerMap[key as SQLOperationType](
          obj[key],
          context,
          nodes,
          edges,
          context.currentSubQueryGroupNodeId ? context.currentSubQueryGroupNodeId : lastNodeId,
          createdTableNodesMap,
          accumulatedEstimatedRows,
          loopId
      );
      if (currentResult.nodeId) {
        lastNodeId = currentResult.nodeId;
      }
      if (currentResult.estimatedRows != null) {
        accumulatedEstimatedRows = currentResult.estimatedRows;
      }
    }
  }
  return { nodeId: lastNodeId, estimatedRows: accumulatedEstimatedRows || 0 };
};


const handleSubQuery = (
    subQueryObj: any,
    context: ParseContext,
    nodes: SQLExplainNode[],
    edges: SQLExplainEdge[],
    parentNodeId: string | null,
    createdTableNodesMap: Map<string, Set<string>>,
    estimatedRows: number | null
): NodeResult => {
  // Create a container for the subquery, attached to the outer container.
  const subqueryGroupNodeId = `subquery_${context.nodeIdCounter++}`;
  const defaultWidth = 400;
  const defaultHeight = 300;
  const subqueryGroupNode: SQLExplainNode = {
    id: subqueryGroupNodeId,
    type: 'sql-sub-query-node',
    data: {
      label: subqueryGroupNodeId,
      type: 'group',
      width: defaultWidth,
      height: defaultHeight,
    },
    position: { x: 0, y: 0 },
    parentId: parentNodeId || undefined, // attach to the outer container
    extent: 'parent'
  };
  nodes.push(subqueryGroupNode);

  // Build the subquery's child nodes using the container as parent.
  const subqueryNodes: SQLExplainNode[] = [];
  const subqueryEdges: SQLExplainEdge[] = [];
  const subqueryResult = recursiveNodeBuilder(
      subQueryObj.query_block,
      context,
      subqueryNodes,
      subqueryEdges,
      subqueryGroupNode.id, // pass the container's ID
      createdTableNodesMap,
      { hasNestedLoop: false, tableNames: new Set(), unionCount: 0, subqueryCount: 0 },
      estimatedRows,
      subqueryGroupNode.id
  );

  // Ensure each subquery node is attached to the container.
  subqueryNodes.forEach(node => {
    if (!node.parentId) {
      node.parentId = subqueryGroupNode.id;
    }
    node.extent = 'parent';
  });
  nodes.push(...subqueryNodes);
  edges.push(...subqueryEdges);

  // Create a Query Result node for the subquery and attach it to the container.
  const queryResultNodeId = `query_result_${subqueryGroupNodeId}`;
  const queryResultNode: SQLExplainNode = {
    id: queryResultNodeId,
    type: 'sql-explain-node',
    data: {
      label: 'Query Result',
      type: 'result',
      queryCost: subQueryObj.cost_info?.query_cost || null,
      estimatedRows: subqueryResult.estimatedRows || 'N/A',
    },
    position: { x: 0, y: 0 },
    parentId: subqueryGroupNode.id,
    extent: 'parent'
  };
  nodes.push(queryResultNode);

  if (subqueryResult.nodeId) {
    edges.push(createEdge(subqueryResult.nodeId, queryResultNodeId, context));
  }

  return { nodeId: queryResultNodeId, estimatedRows: subqueryResult.estimatedRows };
};

const handleUnion = (
    unionData: any,
    context: ParseContext,
    nodes: SQLExplainNode[],
    edges: SQLExplainEdge[],
    parentNodeId: string | null,
    createdTableNodesMap: Map<string, Set<string>>,
    estimatedRows: number | null
): NodeResult => {
  // Create the union node and attach it directly to the outer container.
  const unionNode = createUnionNode(context);
  unionNode.parentId = parentNodeId || undefined;
  let unionEstimatedRows: number = 0;
  nodes.push(unionNode);

  unionData.query_specifications.forEach((queryBlock: any, index: number) => {
    // Create a new group container for this branch. Its parent is the outer container.
    context.nodeIdCounter++;
    const groupNodeId = `group_${index}`;
    const defaultWidth = 400;
    const defaultHeight = 300;
    const groupNode: SQLExplainNode = {
      id: groupNodeId,
      type: 'sql-sub-query-node',
      data: {
        label: `group_${index}`,
        type: 'group',
        width: defaultWidth,
        height: defaultHeight,
      },
      position: { x: 0, y: 0 },
      parentId: parentNodeId || undefined, // attach to the outer container, not the union node
      extent: 'parent'
    };
    nodes.push(groupNode);

    // Build the branch's nodes using the new group container as parent.
    const subNodes: SQLExplainNode[] = [];
    const queryBlockResult = recursiveNodeBuilder(
        queryBlock.query_block,
        context,
        subNodes,
        edges,
        groupNode.id, // use the new group container as parent
        createdTableNodesMap,
        { hasNestedLoop: false, tableNames: new Set(), unionCount: 0, subqueryCount: 0 },
        estimatedRows,
        groupNode.id
    );

    // Ensure all branch nodes are attached to the group container.
    subNodes.forEach((node) => {
      if (!node.parentId) {
        node.parentId = groupNode.id;
      }
    });
    nodes.push(...subNodes);

    unionEstimatedRows += queryBlockResult.estimatedRows || 0;
    // Create a result node for this branch, as a child of the group container.
    const resultNode = createResultNode(queryBlock.query_block, context, queryBlockResult.estimatedRows, groupNode.id);
    nodes.push(resultNode);
    if (queryBlockResult.nodeId) {
      edges.push(createEdge(queryBlockResult.nodeId, resultNode.id, context));
    }
    edges.push(createEdge(groupNode.id, unionNode.id, context));

    // case when there is a 'table' entry together with others
    if (queryBlock.query_block.table){
      const tableNode = nodes.filter(node => node.data.type === 'table' && node.parentId === groupNodeId);
      tableNode.forEach(table => {
        edges.push(createEdge(table.id, resultNode.id, context));
      })
    }
  });

  unionNode.data.estimatedRows = unionEstimatedRows;

  return { nodeId: unionNode.id, estimatedRows: unionEstimatedRows };
};

const handleQueryBlock = (
    queryBlock: any,
    context: ParseContext,
    nodes: SQLExplainNode[],
    edges: SQLExplainEdge[],
    parentNodeId: string | null,
    createdTableNodesMap: Map<string, Set<string>>,
    estimatedRows: number | null
): NodeResult => {
  const resultNode = createResultNode(queryBlock, context, estimatedRows);
  nodes.push(resultNode);

  if (parentNodeId && !edges.some(edge => edge.source === parentNodeId && edge.target === resultNode.id)) {
    const parentNode = nodes?.find(node => node.id === parentNodeId);
    if (parentNode && parentNode.data.type === 'union') {
      const unionGroupsNodesId = edges.filter(edge => edge.target === parentNodeId).map(edge => edge.source);
      const unionNodes = nodes.filter(node => unionGroupsNodesId.includes(node.parentId));
      const queryResultCostSum = unionNodes.reduce((acc, node) => {
        if (node.data.type === 'result'){
            return acc + (node.data.queryCost || 0);
        }
        return acc;
      }, 0);
      resultNode.data.queryCost = queryResultCostSum;
    }

    edges.push(createEdge(parentNodeId, resultNode.id, context));
  }

  return { nodeId: resultNode.id, estimatedRows };
};
const handleGroupingOperation = (
    groupingOperation: any,
    context: ParseContext,
    nodes: SQLExplainNode[],
    edges: SQLExplainEdge[],
    parentNodeId: string | null,
    createdTableNodesMap: Map<string, Set<string>>,
    estimatedRows: number | null,
    groupId: string | null
): NodeResult => {
  const groupByNode = createGroupByNode(context, groupId || undefined);
  nodes.push(groupByNode);
  context.currentYPosition += context.verticalSpacing;

  if (parentNodeId && !edges.some(edge => edge.source === parentNodeId && edge.target === groupByNode.id)) {
    edges.push(createEdge(parentNodeId, groupByNode.id, context));
  }

  return { nodeId: groupByNode.id, estimatedRows };
};


const handleTable = (
    table: any,
    context: ParseContext,
    nodes: SQLExplainNode[],
    edges: SQLExplainEdge[],
    parentNodeId: string | null,
    createdTableNodesMap: Map<string, Set<string>>,
    estimatedRows: number | null,
    loopId: string
): NodeResult => {
  const currentSet = createdTableNodesMap.get(loopId) || new Set<string>();

  if (currentSet.has(table.table_name)) {
    return { nodeId: parentNodeId, estimatedRows };
  }

  currentSet.add(table.table_name);
  createdTableNodesMap.set(loopId, currentSet);

  const tableNode = createTableNode(table, context, context.currentSubQueryGroupNodeId ? context.currentSubQueryGroupNodeId : undefined);
  nodes.push(tableNode);

  const tableRows = table.rows_produced_per_join || estimatedRows || 0;

  const parentNodeIdGroup = nodes.find(node => node.id === parentNodeId)?.parentId;
  if (parentNodeIdGroup) {
      edges.push(createEdge(parentNodeIdGroup, tableNode.id, context));
    }
  return { nodeId: tableNode.id, estimatedRows: tableRows };
};

const handleOrderingOperation = (
    orderingOperation: any,
    context: ParseContext,
    nodes: SQLExplainNode[],
    edges: SQLExplainEdge[],
    parentNodeId: string | null,
    createdTableNodesMap: Map<string, Set<string>>,
    estimatedRows: number | null,
    groupId: string | null
): NodeResult => {
  const sortingNode = createSortingNode(context, groupId || undefined);
  nodes.push(sortingNode);

  context.currentYPosition += context.verticalSpacing;

  if (parentNodeId && !edges.some(edge => edge.source === parentNodeId && edge.target === sortingNode.id)) {
    edges.push(createEdge(parentNodeId, sortingNode.id, context));
  }

  return { nodeId: sortingNode.id, estimatedRows };
};

const handleNestedLoop = (
    nestedLoops: any[],
    context: ParseContext,
    nodes: SQLExplainNode[],
    edges: SQLExplainEdge[],
    parentNodeId: string | null,
    createdTableNodesMap: Map<string, Set<string>>,
    estimatedRows: number | null,
    loopId: string
): NodeResult => {
  let lastNodeId: string | null = null;
  let finalEstimatedRows: number | null = estimatedRows;

  const nestedLoopId = `${loopId}-nestedloop-${context.nodeIdCounter}`;

  if (!createdTableNodesMap.has(nestedLoopId)) {
    createdTableNodesMap.set(nestedLoopId, new Set<string>());
  }

  const currentSet = createdTableNodesMap.get(nestedLoopId)!;

  // 'nested loop' entry is unordered. we want to maintain a consistent flow.
  const orderedTables = nestedLoops.sort((a, b) => {
    if (a.table && b.table) {
      if (b.table?.attached_subqueries)
        return 1;
        if (a.table?.attached_subqueries)
        return -1;
    }
  });
  let lastJoinNode: SQLExplainNode | null = null;
  orderedTables.forEach((loop: any, index: number) => {
    context.currentXPosition -= context.horizontalSpacing;

    if (loop.table) {
      if (loop.table.materialized_from_subquery) {
        const subQueryResult = handleSubQuery(
            loop.table.materialized_from_subquery,
            context,
            nodes,
            edges,
            parentNodeId,
            createdTableNodesMap,
            estimatedRows
        );

        const outerTableNodeData = {
          ...loop.table,
          estimatedRows: subQueryResult.estimatedRows || 'N/A',
        };
        const outerTableNode = createTableNode(outerTableNodeData, context, parentNodeId);
        nodes.push(outerTableNode);

        if (subQueryResult.nodeId) {
          const subQueryResultParentId = nodes.find(node => node.id === subQueryResult.nodeId)?.parentId;
          if (subQueryResultParentId) {
            edges.push(createEdge(subQueryResultParentId, outerTableNode.id, context));
          }
        }

        if (index !== 0 && lastNodeId) {
          const maxFilteredRows = Math.max(Number(loop.table.rows_produced_per_join) || 0, context.latestRowsAfterFilter || 0);
          finalEstimatedRows = maxFilteredRows;

          const joinNode = createJoinNode(maxFilteredRows, loop.table.filtered || 'No Filter', context, parentNodeId);
          nodes.push(joinNode);

          // Connect the previous node and the current outer table node to the join node
          edges.push(createEdge(lastNodeId, joinNode.id, context));
          edges.push(createEdge(outerTableNode.id, joinNode.id, context));

          lastNodeId = joinNode.id;
        } else {
          lastNodeId = outerTableNode.id;
        }

      } else
        if (loop.table.attached_subqueries){
        const subQueriesResult = handleMultipleSubQueries(
            loop.table.attached_subqueries,
            context,
            nodes,
            edges,
            parentNodeId,
            createdTableNodesMap,
            estimatedRows
        );

        if (subQueriesResult.nodeId) {
          lastNodeId = subQueriesResult.nodeId;
        }
        if (subQueriesResult.estimatedRows != null) {
          finalEstimatedRows = subQueriesResult.estimatedRows;
        }
        const tableNode = createTableNode(loop.table, context, parentNodeId);
        nodes.push(tableNode);
        currentSet.add(loop.table.table_name);

          const maxFilteredRows = Math.max(Number(loop.table.rows_produced_per_join) || 0, context.latestRowsAfterFilter || 0);
          finalEstimatedRows = maxFilteredRows;

          const joinNode = createJoinNode(maxFilteredRows, loop.table.filtered || 'No Filter', context, parentNodeId);
          nodes.push(joinNode);

          edges.push(createEdge(tableNode.id, joinNode.id, context));

          const subQueryParentId = nodes.find(node => node.id === subQueriesResult.nodeId)?.parentId;
            if (subQueryParentId) {
                edges.push(createEdge(subQueryParentId, tableNode.id, context));
            }

          if (lastJoinNode?.id !== lastNodeId && lastJoinNode?.id !== tableNode.id){
            edges.push(createEdge(lastJoinNode?.id, joinNode.id, context));
          }

          lastNodeId = joinNode.id;
          lastJoinNode = joinNode;
      }
      else {
        const tableNode = createTableNode(loop.table, context, parentNodeId);
        nodes.push(tableNode);
        currentSet.add(loop.table.table_name);

        if (index !== 0 && lastNodeId) {
          const maxFilteredRows = Math.max(Number(loop.table.rows_produced_per_join) || 0, context.latestRowsAfterFilter || 0);
          finalEstimatedRows = maxFilteredRows;

          const joinNode = createJoinNode(maxFilteredRows, loop.table.filtered || 'No Filter', context, parentNodeId);
          nodes.push(joinNode);

          edges.push(createEdge(lastNodeId, joinNode.id, context));
          edges.push(createEdge(tableNode.id, joinNode.id, context));

          lastNodeId = joinNode.id;
          lastJoinNode = joinNode;
        } else {
          lastNodeId = tableNode.id;
        }
      }
    }
  });

  if (lastNodeId && parentNodeId ) {
    const parentNode = context?.nodes?.find(node => node.id === parentNodeId);
    if (parentNode && parentNode.data.type !== 'group' ) {
      edges.push(createEdge(lastNodeId, parentNodeId, context));
    }
  }

  return { nodeId: lastNodeId, estimatedRows: finalEstimatedRows };
};

const handleDuplicatesRemoval = (
    duplicatesRemoval: any,
    context: ParseContext,
    nodes: SQLExplainNode[],
    edges: SQLExplainEdge[],
    lastNodeId: string | null,
    createdTableNodesMap: Map<string, Set<string>>,
    estimatedRows: number | null,
    parentNodeId: string
): NodeResult => {
  const distinctNode = createDistinctNode(context, parentNodeId || undefined);
  nodes.push(distinctNode);
  context.currentYPosition += context.verticalSpacing;

  if (
      lastNodeId &&
      !edges.some(
          (edge) => edge.source === parentNodeId && edge.target === distinctNode.id
      )
  ) {
    edges.push(createEdge(lastNodeId, distinctNode.id, context));
  }

  return { nodeId: distinctNode.id, estimatedRows };
};

const handleMultipleSubQueries = (
    subQueries: any[],
    context: ParseContext,
    nodes: SQLExplainNode[],
    edges: SQLExplainEdge[],
    parentNodeId: string | null,
    createdTableNodesMap: Map<string, Set<string>>,
    estimatedRows: number | null
): NodeResult => {
  let lastSubQueryNodeId: string | null = null;
  let accumulatedEstimatedRows = estimatedRows;

  subQueries.forEach((subQueryObj) => {
    const subQueryResult = handleSubQuery(
        subQueryObj,
        context,
        nodes,
        edges,
        parentNodeId,
        createdTableNodesMap,
        accumulatedEstimatedRows
    );

    if (subQueryResult.nodeId) {
      lastSubQueryNodeId = subQueryResult.nodeId;
    }
    if (subQueryResult.estimatedRows != null) {
      accumulatedEstimatedRows = subQueryResult.estimatedRows;
    }
  });

  return { nodeId: lastSubQueryNodeId, estimatedRows: accumulatedEstimatedRows };
};

const handleSelectListSubQueries = (
    subQueries: any[],
    context: ParseContext,
    nodes: SQLExplainNode[],
    edges: SQLExplainEdge[],
    parentNodeId: string | null,
    createdTableNodesMap: Map<string, Set<string>>,
    estimatedRows: number | null,
    groupId: string
): NodeResult => {
  let lastSubQueryNodeId: string | null = null;
  let accumulatedEstimatedRows = estimatedRows;

  const selectListNode = createSelectListNode(context, parentNodeId || undefined);
  nodes.push(selectListNode);

  const isParentNodeContainer = groupId && groupId !== 'default';

  subQueries.forEach((subQueryObj) => {

    // Create a container for the subquery, attached to the outer container.
    const subqueryGroupNodeId = `subquery_${context.nodeIdCounter++}`;
    const defaultWidth = 400;
    const defaultHeight = 300;
    const subqueryGroupNode: SQLExplainNode = {
      id: subqueryGroupNodeId,
      type: 'sql-sub-query-node',
      data: {
        label: subqueryGroupNodeId,
        type: 'group',
        width: defaultWidth,
        height: defaultHeight,
      },
      position: { x: 0, y: 0 },
      parentId: isParentNodeContainer ? parentNodeId || undefined : undefined, // attach to the outer container
      extent: 'parent'
    };
    nodes.push(subqueryGroupNode);

    // Build the subquery's child nodes using the container as parent.
    const subqueryNodes: SQLExplainNode[] = [];
    const subqueryEdges: SQLExplainEdge[] = [];
    const subqueryResult = recursiveNodeBuilder(
        subQueryObj.query_block,
        context,
        subqueryNodes,
        subqueryEdges,
        subqueryGroupNode.id, // pass the container's ID
        createdTableNodesMap,
        { hasNestedLoop: false, tableNames: new Set(), unionCount: 0, subqueryCount: 0 },
        estimatedRows,
        subqueryGroupNode.id
    );

    // Ensure each subquery node is attached to the container.
    subqueryNodes.forEach(node => {
      if (!node.parentId) {
        node.parentId = subqueryGroupNode.id;
      }
      node.extent = 'parent';
    });
    nodes.push(...subqueryNodes);
    edges.push(...subqueryEdges);

    // Create a Query Result node for the subquery and attach it to the container.
    const queryResultNodeId = `query_result_${subqueryGroupNodeId}`;
    const queryResultNode: SQLExplainNode = {
      id: queryResultNodeId,
      type: 'sql-explain-node',
      data: {
        label: 'Query Result',
        type: 'result',
        queryCost: subQueryObj.cost_info?.query_cost || null,
        estimatedRows: subqueryResult.estimatedRows || 'N/A',
      },
      position: { x: 0, y: 0 },
      parentId: subqueryGroupNode.id,
      extent: 'parent'
    };
    nodes.push(queryResultNode);

    if (subqueryResult.nodeId) {
      edges.push(createEdge(subqueryResult.nodeId, queryResultNodeId, context));
    }

    lastSubQueryNodeId = queryResultNodeId;
    accumulatedEstimatedRows = subqueryResult.estimatedRows;

    edges.push(createEdge(subqueryGroupNodeId, selectListNode.id, context));
  });

  // if parent node is not a container, it means it is the lastest node that was created
  // therefore, we need to create an edge to the same previous node
    if (!isParentNodeContainer && parentNodeId) {
      edges.push(createEdge(parentNodeId, selectListNode.id || '', context));
    }
  return { nodeId: selectListNode.id, estimatedRows: accumulatedEstimatedRows };
};

const keyToHandlerMap: Record<
    any,
    any
> = {
  [SQLOperationType.QUERY_RESULT]: handleQueryBlock,
  [SQLOperationType.TABLE]: handleTable,
  [SQLOperationType.GROUP_BY]: handleGroupingOperation,
  [SQLOperationType.SORT]: handleOrderingOperation,
  [SQLOperationType.NESTED_LOOP]: handleNestedLoop,
  [SQLOperationType.UNION]: handleUnion,
  [SQLOperationType.SUBQUERY]: handleSubQuery,
  [SQLOperationType.DISTINCT]: handleDuplicatesRemoval,
  [SQLOperationType.MULTIPLE_SUBQUERIES]: handleMultipleSubQueries,
  [SQLOperationType.SELECT_LIST_SUBQUERIES]: handleSelectListSubQueries
};

